import { default as _500HMSCCdc0xIMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/500.vue?macro=true";
import { default as channel_45adminO0D2wh3TGqMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue?macro=true";
import { default as _91categoryId_93qT3fplVzZYMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue?macro=true";
import { default as _91taxonomyId_93r6y0Be9ks3Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue?macro=true";
import { default as compliance_45insightsHWR3a6XjNMMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue?macro=true";
import { default as _91memberId_93fayzWSGp4QMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue?macro=true";
import { default as search8fz9criBWKMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/search.vue?macro=true";
import { default as sites_45and_45membersRXC5rlbZlLMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue?macro=true";
import { default as _91teamId_93xhb22Mbxp9Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue?macro=true";
import { default as indexPIX3epwa62Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue?macro=true";
import { default as required_45actions9XTcR9Hp8CMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue?macro=true";
import { default as unread_45posts7iuL62JwGvMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue?macro=true";
import { default as _91channelId_93BmfkEX79L6Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId].vue?macro=true";
import { default as create_45study_45siteAVpWHmcQXJMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue?macro=true";
import { default as create_45team3BQO9NSIKxMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue?macro=true";
import { default as invite_45memberHwyWMfMwgiMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue?macro=true";
import { default as post_45creationtma55AK1vWMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue?macro=true";
import { default as required_45actionsLjGFZQqWCpMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channels/required-actions.vue?macro=true";
import { default as search9v91A9rtl5Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channels/search.vue?macro=true";
import { default as unread_45postsfjxQLAIdnaMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/channels/unread-posts.vue?macro=true";
import { default as indexnie6CmLAGcMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/equipment/index.vue?macro=true";
import { default as index10ldSbcPAIMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/index.vue?macro=true";
import { default as detaildvIQFoto5ZMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/post/[postId]/[version]/detail.vue?macro=true";
import { default as indexZqWPN4mQrfMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/settings/[clientId]/index.vue?macro=true";
import { default as signinYk6eQgIOc7Meta } from "/codebuild/output/src761562806/src/eec-webapp/pages/signin.vue?macro=true";
import { default as indexdHlizammdcMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/teams/index.vue?macro=true";
import { default as terms_45of_45serviceGYJ40WkHXHMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/terms-of-service.vue?macro=true";
import { default as update_45profileb4yHQnpdnGMeta } from "/codebuild/output/src761562806/src/eec-webapp/pages/update-profile.vue?macro=true";
export default [
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId",
    path: "/channel/:channelId()",
    meta: _91channelId_93BmfkEX79L6Meta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-channel-admin",
    path: "channel-admin",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-classifications-taxonomyId",
    path: "classifications/:taxonomyId()",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-classifications-taxonomyId-categoryId",
    path: ":categoryId()",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-channelId-compliance-insights",
    path: "compliance-insights",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-members-memberId",
    path: "members/:memberId()",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-search",
    path: "search",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/search.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-sites-and-members",
    path: "sites-and-members",
    meta: sites_45and_45membersRXC5rlbZlLMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-teams-teamId",
    path: "teams/:teamId()",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline",
    path: "timeline",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-required-actions",
    path: "timeline/required-actions",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-unread-posts",
    path: "timeline/unread-posts",
    meta: unread_45posts7iuL62JwGvMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-directory-channelId-create-study-site",
    path: "/channel/directory/:channelId()/create-study-site",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-create-team",
    path: "/channel/directory/:channelId()/create-team",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-invite-member",
    path: "/channel/directory/:channelId()/invite-member",
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue").then(m => m.default || m)
  },
  {
    name: "channel-post-channelId-post-creation",
    path: "/channel/post/:channelId()/post-creation",
    meta: post_45creationtma55AK1vWMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue").then(m => m.default || m)
  },
  {
    name: "channels-required-actions",
    path: "/channels/required-actions",
    meta: required_45actionsLjGFZQqWCpMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channels/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channels-search",
    path: "/channels/search",
    meta: search9v91A9rtl5Meta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channels/search.vue").then(m => m.default || m)
  },
  {
    name: "channels-unread-posts",
    path: "/channels/unread-posts",
    meta: unread_45postsfjxQLAIdnaMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/channels/unread-posts.vue").then(m => m.default || m)
  },
  {
    name: "equipment",
    path: "/equipment",
    meta: indexnie6CmLAGcMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/equipment/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index10ldSbcPAIMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "post-postId-version-detail",
    path: "/post/:postId()/:version()/detail",
    meta: detaildvIQFoto5ZMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/post/[postId]/[version]/detail.vue").then(m => m.default || m)
  },
  {
    name: "settings-clientId",
    path: "/settings/:clientId()",
    meta: indexZqWPN4mQrfMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/settings/[clientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinYk6eQgIOc7Meta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "teams",
    path: "/teams",
    meta: indexdHlizammdcMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45serviceGYJ40WkHXHMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "update-profile",
    path: "/update-profile",
    meta: update_45profileb4yHQnpdnGMeta || {},
    component: () => import("/codebuild/output/src761562806/src/eec-webapp/pages/update-profile.vue").then(m => m.default || m)
  }
]